/**
 * Culls data selectively from objects
 * @param {object} obj Object to cull
 * @param {object} options Options
 * @param {string[]} options.omit An array of object keys to omit
 * @param {number} options.maxArraySize The largest an array can be before omitting it
 */
export default function cullData(obj, options = {}) {
  if (Array.isArray(obj)) {
    for (const x of obj) {
      cullData(x, options);
    }

    return;
  }
  if (typeof obj !== 'object' || obj === null || typeof obj === 'undefined') {
    return;
  }

  const { omit, maxArraySize } = options;
  const keys = Object.keys(obj);

  for (const key of keys) {
    // Do _not_ truncate api_request_parts data
    if (key === 'parts') {
      continue;
    }
    if (omit && omit.includes(key)) {
      obj[key] = '[omitted]';
    } else if (
      maxArraySize &&
      Array.isArray(obj[key]) &&
      obj[key].length > maxArraySize
    ) {
      obj[key] = [
        ...obj[key].slice(0, maxArraySize),
        `(${obj[key].slice(maxArraySize).length} more items)`,
      ];
    } else {
      cullData(obj[key], options);
    }
  }
}
