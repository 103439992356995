<template>
  <div
    :class="sidePaneClass"
    @click.stop
  >
    <slot v-if="visible" />
  </div>
</template>

<script>
export default {
  name: 'SidePane',
  props: {
    visible: { type: Boolean, required: true },
  },

  computed: {
    sidePaneClass() {
      return {
        'side-pane': true,
        visible: this.visible,
      };
    },
  },

  watch: {
    visible(value) {
      if (value) {
        setTimeout(() => window.addEventListener('click', this.handleClickOut), 300);
      } else {
        window.removeEventListener('click', this.handleClickOut);
      }
    },
  },

  methods: {
    handleClickOut() {
      this.$emit('request-close');
    },
  },
};
</script>

<style lang="scss" scoped>
.side-pane {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
  box-sizing: border-box;
  min-width: 440px;
  overflow-y: auto;
  padding: 1em;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  width: 33%;
  z-index: 5;

  &.visible {
    transform: translateX(0%);
  }
}
</style>
