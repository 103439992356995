<template>
  <span>
    <a @click="$emit('click')">{{ $slots.default[0].text.trim() }}</a>
  </span>
</template>

<script>
export default {
  name: 'Link',
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
  text-decoration: underline;
}
</style>
