<template>
  <div
    ref="page"
    class="job-detail"
  >
    <h3>{{ job.label }}</h3>

    <table v-loading="!job.id">
      <tbody>
        <tr>
          <th>Job ID</th>
          <td>{{ job.id }}</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>{{ job.type }}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{{ job.status }}</td>
        </tr>
        <tr>
          <th>Expected Parts</th>
          <td>
            {{ expectedParts }}
            ({{ completedParts }} completed of {{ totalRequests }})
          </td>
        </tr>
        <tr>
          <th>Created At</th>
          <td>{{ job.created_at | dateString({ includeTime: true, includeTZ: true }) }}</td>
        </tr>
        <tr>
          <th>Updated At</th>
          <td>{{ job.updated_at | dateString({ includeTime: true, includeTZ: true }) }}</td>
        </tr>
        <tr>
          <th>Initiated By</th>
          <td>{{ job.user | fullName }}</td>
        </tr>
        <tr v-if="job.parent_type === 'App/Models/Audience'">
          <th>Parent Audience</th>
          <td><a :href="'/audiences/library/' + job.parent_id">{{ job.parent_id }}</a></td>
        </tr>
        <tr v-else-if="job.publish_audience_id">
          <th>Parent Audience</th>
          <td><a :href="'/audiences/library/' + job.publish_audience_id">{{ job.publish_audience_id }}</a></td>
        </tr>
        <tr v-if="job.batch_job_id">
          <th>Batch Job</th>
          <td><a :href="'/jobs/' + job.batch_job_id">{{ job.batch_job_id }}</a></td>
        </tr>
        <tr v-if="reportingJob && externalLink">
          <!-- && job.status !== 'COMPLETE'"> -->
          <th>External Pending Requests</th>
          <td>
            <a
              :href="externalLink"
              target="_blank"
            >
              Details
            </a>
          </td>
        </tr>
        <tr>
          <th>
            <el-dropdown
              v-loading="retrying"
              size="small"
              split-button
              @click="retry('error')"
              @command="retry"
            >
              Retry Job
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  Entire Job
                </el-dropdown-item>
                <el-dropdown-item command="incomplete">
                  Incomplete/Failed
                </el-dropdown-item>
                <el-dropdown-item command="error">
                  Errors Only
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </th>
          <td>
            <el-button
              type="text"
              @click="destroy()"
            >
              Delete Job
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>

    <el-tabs
      v-if="job.id"
      v-model="tab"
      type="card"
    >
      <el-tab-pane
        label="Requests"
        name="requests"
      >
        <div class="request-table">
          <data-table
            ref="dataTable"
            :actions="dataTable.actions"
            :data-class="request"
            :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
            disable-archive
            disable-checkboxes
            disable-search
            :page-size="dataTable.pageSize"
            permission-suffix="geoframes"
            @data-fetch="dataLoaded"
          >
            <el-table-column
              fixed
              label="Request ID"
              prop="request_id"
              sortable="custom"
              :width="270"
            />
            <el-table-column
              label="Request URL"
              prop="request_url"
              sortable="custom"
            />
            <el-table-column
              label="Response Code"
              prop="response_code"
              sortable="custom"
              :width="130"
            />
            <el-table-column
              label="Status"
              prop="status"
              sortable="custom"
              :width="100"
            />
            <el-table-column
              label="ID"
              prop="id"
              sortable="custom"
              :width="100"
            />
            <el-table-column
              label="S3 Backed"
              :width="110"
            >
              <template slot-scope="{ row }">
                <i
                  v-if="row.s2_level"
                  class="fa fa-check"
                  style="color:#3a3"
                />
                <i
                  v-else
                  class="fa fa-times"
                  style="color:#a33"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Request"
              :width="110"
            >
              <template slot-scope="{ row }">
                <json-viewer
                  :data="row.request"
                  title="Request"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Response"
              :width="110"
            >
              <template slot-scope="{ row }">
                <json-viewer
                  :data="row.response"
                  title="Response"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Callback"
              :width="110"
            >
              <template slot-scope="{ row }">
                <json-viewer
                  :data="row.callback"
                  title="Callback"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Meta"
              :width="110"
            >
              <template slot-scope="{ row }">
                <json-viewer
                  :data="row.meta"
                  title="Meta"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Parts"
              :width="110"
            >
              <template slot-scope="{ row }">
                <span v-if="row.parts">
                  {{ row.parts.length }}
                  (<ol-link @click="handleViewParts(row)">
                    View
                  </ol-link>)
                </span>
                <span v-else>
                  N/A
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :width="145"
              prop="created_at"
              label="Created"
              sortable="custom"
            >
              <template slot-scope="{ row }">
                {{ row.created_at | dateString({ includeTime: true, includeSeconds: true, includeTZ: true }) }}
              </template>
            </el-table-column>
            <el-table-column
              :width="145"
              prop="updated_at"
              label="Updated"
              sortable="custom"
            >
              <template slot-scope="{ row }">
                {{ row.updated_at | dateString({ includeTime: true, includeSeconds: true, includeTZ: true }) }}
              </template>
            </el-table-column>
          </data-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="Logs"
        name="logs"
      >
        <div class="request-table">
          <data-table
            ref="logDataTable"
            :data-class="logs"
            :default-sort="{ sortBy: logsDataTable.sortBy, order: logsDataTable.order }"
            disable-archive
            disable-checkboxes
            disable-search
            :page-size="dataTable.pageSize"
            @data-fetch="logDataLoaded"
          >
            <el-table-column
              prop="request_id"
              label="API Request ID"
              sortable="custom"
              :width="300"
            />
            <el-table-column
              prop="service"
              label="Service"
              sortable="custom"
              :width="110"
            />
            <el-table-column
              prop="level"
              label="Level"
              sortable="custom"
              :width="110"
            >
              <template slot-scope="{ row }">
                <el-tag
                  :type="logType(row.level)"
                  size="mini"
                >
                  {{ row.level }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="description"
              label="Description"
              sortable="custom"
            />
            <el-table-column
              prop="meta"
              label="Meta"
              :width="50"
            >
              <template slot-scope="{ row }">
                <json-viewer
                  :data="row.meta"
                  title="Meta"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="created_at"
              label="Timestamp"
              sortable="custom"
              :width="250"
            >
              <template slot-scope="{ row }">
                {{ row.created_at | dateString({ includeTime: true, includeSeconds: true, includeTZ: true }) }}
              </template>
            </el-table-column>
          </data-table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <side-pane
      :visible="!!viewParts && viewParts.length > 0"
      @request-close="viewParts = null"
    >
      <el-pagination
        background
        :current-page.sync="currentPartPage"
        layout="prev, pager, next"
        :total="totalParts"
      />
      <el-table :data="paginatedParts">
        <el-table-column type="expand">
          <template slot-scope="{ row }">
            <tree-view :data="row.payload" />
          </template>
        </el-table-column>
        <el-table-column
          label="ID"
          prop="id"
        />
        <el-table-column label="Success">
          <template slot-scope="{ row }">
            {{ row.success }}
          </template>
        </el-table-column>
      </el-table>
    </side-pane>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { job as jobApi } from '@/adonis-api';
import JsonViewer from '../../global/JsonViewer.vue';
import DataTable from '../../global/DataTable.vue';
import Link from '../../global/Link.vue';
import SidePane from '../../global/SidePane.vue';
import cullData from '@/helpers/cull-data';

const PAGE_SIZE = 50;

const REPORTING_API_PENDING_LOOKUP = process.env.VUE_APP_REPORT_API_PENDING_QUERIES;

export default {
  name: 'PageJobDetail',
  components: { DataTable, JsonViewer, OlLink: Link, SidePane },

  data() {
    return {
      currentPage: 1,
      currentPartPage: 1,
      dataTable: Object.freeze({
        order: 'descending',
        sortBy: 'created_at',
      }),
      logsDataTable: Object.freeze({
        order: 'ascending',
        sortBy: 'created_at',
      }),
      logs: Object.freeze({
        find: this.getLogs,
      }),
      job: {},
      pageSize: PAGE_SIZE,
      request: Object.freeze({
        find: this.getReqs,
      }),
      retrying: false,
      tab: 'requests',
      logTableData: [],
      tableData: [],
      treeOptions: Object.freeze({
        maxDepth: 2,
      }),
      viewParts: null,
    };
  },

  computed: {
    completedParts() {
      return _get(this.job, '__meta__.completed_requests_count');
    },

    totalRequests() {
      return _get(this.job, '__meta__.requests_count');
    },

    expectedParts() {
      return _get(this.job, 'expected_parts') || _get(this.job, '__meta__.requests_count');
    },

    externalLink() {
      if (this.tableData.length && ['/audienc', '/reports'].includes(this.tableData[0].request_url.substr(0, 8))) {
        return  REPORTING_API_PENDING_LOOKUP + this.tableData[0].request_id;
      }

      return null;
    },

    paginatedParts() {
      return this.viewParts
        ? this.viewParts.slice(
          (this.currentPartPage - 1) * 10,
          this.currentPartPage * 10,
        )
        : [];
    },

    reportingJob() {
      const reportingJobTypes = [
        'ANALYTICS',
        'ATTRIBUTION',
        'HEATMAP',
      ];

      return reportingJobTypes.includes(this.job.type);
    },

    totalParts() {
      return (this.viewParts || []).length;
    },
  },

  async created() {
    try {
      this.job = await jobApi.findOne(this.$route.params.id);
    } catch (e) {
      this.$notify.error('There was a problem fetching the job information.');
      this.$reportError(e);
    }
  },

  methods: {
    dataLoaded(data) {
      this.tableData = data;
    },

    logDataLoaded(data) {
      this.logTableData = data;
    },

    async destroy() {
      try {
        await this.$confirm(
          'Are you sure you want to delete this job?',
          'Delete',
        );
        await jobApi.delete(this.job.id);
        this.$message('Job has been deleted.');
      } catch (e) {
        if (e !== 'cancel') {
          this.$reportError(e);
        }
      }
    },

    async getLogs(params) {
      return await jobApi.getLogs(this.job.id, params);
    },

    async getReqs(params) {
      const response = await jobApi.getRequests(this.job.id, params);
      cullData(response.data, {
        omit: ['coordinates'],
        maxArraySize: 50,
      });

      return response;
    },

    handleViewParts(request) {
      this.viewParts = [...request.parts].sort((a, b) => {
        if (a.success > b.success) return 1;
        else if (b.success > a.success) return -1;
        return 0;
      });
    },

    logType(val) {
      if (val === 'INFO') {
        return '';
      } else if (val === 'DEBUG') {
        return 'info';
      }
      return val.toLowerCase();
    },

    async retry(command) {
      let params = null;

      if (command === 'error') {
        params = { error: true };
      }
      if (command === 'incomplete') {
        params = { incomplete: true };
      }

      this.retrying = true;
      await jobApi.retry(this.job.id, params);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.job-detail {
  margin: 1em;
}

table {
  th {
    padding-right: 0.4em;
    text-align: left;
  }
  td {
    font-family: Inconsolata, monospace;
  }
}

.request-table {
  margin-top: 1em;
}

.request-loader {
  align-items: center;
  color: #666;
  display: flex;
  flex-direction: column;
  margin-top: 1em;

  .spinner {
    margin-top: 1em;
  }
}
</style>
